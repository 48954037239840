/****************Navbar*********************/
nav {
  z-index: 2;
    &.navbar-expand-lg .navbar-collapse {
    justify-content: end;
  }
  .container {
    .navbar-brand {
      max-width: 17.25rem;
      height: 8rem;
        .logo {
          width: 7.25rem;
        }
    }
  }
  /****************Nav link*********************/
  ul li {
    text-transform: uppercase;
      a.nav-link {
        margin-right: .75rem;
        margin-left: .75rem;
        border-top: 0.175rem solid var(--primary-dark); 
        font-weight: 500;
        border-bottom: 0.175rem solid transparent;
        letter-spacing: 0.375rem;
        cursor: pointer;
        // Change it to active in nav when first visit/
          &:hover, &:active, &:focus {
            border-bottom: 0.175rem solid var(--primary-red);
          }
      }
  }
}
