.contacts {
  background:  var(--primary-dark);
  padding: 3rem 0;
    h1 {
      color: var(--primary-white);
      padding: 3rem 0;
      text-transform: uppercase;
    }
    p {
      color: var(--primary-light-grey);
      font-size: 1.2rem;
    }
    .success-message {
      color: green;
    }
    .error-message {
      color: var(--primary-red);
      text-align: left;
      padding-left: .75rem;
      text-transform: capitalize;
    }
    .container {
          padding: 4rem 0;
    }
    /* Media */
    @media(max-width:768px){
        overflow-x: hidden;
        .container {
          padding: 0 1rem;
        }
        p {
          padding: 0 1rem;
        } 
    }
}

/* Inputs */
.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  resize: none;
  background: none;
  border: 0;
  border-bottom: 2px solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-red);
  outline: 0!important;
  box-shadow: none!important;
  top: 0;
  margin-bottom: 1rem;
  &:focus {
    background: transparent;
    color: var(--primary-red);
    border-bottom: 0.125rem solid var(--primary-dark-grey) 
  }
}

  textarea {
  height: 16.8rem!important;
}

.contact-btn {
  margin: 2rem 0 0 0;
  width: 25%;
  border: 3px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 10px;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
  transition: 0.3s;
  z-index: 3;
  text-decoration: none;
  background-color: black;
    &:hover {
      color: var(--primary-red);
      border: 3px solid var(--primary-white);
      transition: 0.3s;
      z-index: 3;
  }
}

.line {
  position: relative;
  top: -0.75rem;
  width: 0;
  height: 0.2rem;
  display: inline-block;
  background: var(--primary-red);
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.4rem 0;
}




