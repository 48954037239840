/* Portfolio */
.portfolio-wrapper {
  background-color: var(--primary-dark);
  padding: 3em;
    @media (max-width: 675px){
      padding: 2rem;
      .btn {
        margin-bottom: 1rem;
      }
    }

  h1 {
    text-transform: uppercase;
    text-decoration: overline;
    color: var(--primary-red);
  }
}
.card {
  background-color: var(--primary-slate-grey);
  .btn {
    border: 3px solid var(--primary-red);
    text-transform: uppercase;
    border-radius: 10px;
    padding: 0.625rem 0.75rem 0.75rem 0.75rem;
    color: var(--primary-white);
    transition: 0.3s;
    z-index: 3;
    text-decoration: none;
    background-color: black;
    width: 100%;
      &:hover {
        color: var(--primary-red);
        border: 3px solid var(--primary-white);
        transition: 0.3s;
        z-index: 3;
      }
  }
}
  
// CSS tester
// * {
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important;
// }