// Testimonials
.testimonials {
  background-image: url('../../assets/testimonials-carousel/testimonials-bg.jpg');
  background-size: 120%;
  background-color: var(--primary-black);
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
    .neon-text{
      padding: 3rem 0;
      text-transform: uppercase;
      color: var(--primary-white);
      text-shadow: 
      /* Red glow */
      0 0 7px rgb(255, 0, 0),
      0 0 10px rgb(255, 0, 0),
      0 0 21px rgb(255, 0, 0),
    
      0 0 42px rgb(255, 0, 0),
      0 0 82px rgb(255, 0, 0),
      0 0 92px rgb(255, 0, 0),
      0 0 102px rgb(255, 0, 0),
      0 0 151px rgb(255, 0, 0);
    }
    .testimonials-content {
      text-align: center;
      max-width: 53.125rem;
      margin:0 auto 2rem auto;
      background: var(--primary-transparent-black);
    }
}

// Carousel-Testimonials

.custom-carousel {
  color: var(--primary-light-grey);
  background: transparent;
  margin-top: -2%;
  margin-right:auto;
  margin-left:auto;
  padding-top: 6%;
  padding-bottom:8%;
  padding-left:5%;
  padding-right:5%;
  height: 100%;
    h3 {
      color: var(--primary-red);
      font-weight: 600;
      text-transform: uppercase;
    }
    .carousel-root {
    margin-top: 3% !important;
    margin:auto !important;
    }
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
    img {
      width: 10rem !important;
      padding-top: 2rem;
    }
}




