/* Global Styles */
/* Color Theme Swatches in Hex */
.Man-with-mask-holding-lightsaber-1-hex { color: #F20519; }
.Man-with-mask-holding-lightsaber-2-hex { color: #D90B31; }
.Man-with-mask-holding-lightsaber-3-hex { color: #730202; }
.Man-with-mask-holding-lightsaber-4-hex { color: #260101; }
.Man-with-mask-holding-lightsaber-5-hex { color: #0D0D0D; }

/* Color Theme Swatches in RGBA */
.Man-with-mask-holding-lightsaber-1-rgba { color: rgba(242, 4, 24, 1); }
.Man-with-mask-holding-lightsaber-2-rgba { color: rgba(216, 10, 48, 1); }
.Man-with-mask-holding-lightsaber-3-rgba { color: rgba(114, 2, 2, 1); }
.Man-with-mask-holding-lightsaber-4-rgba { color: rgba(38, 0, 0, 1); }
.Man-with-mask-holding-lightsaber-5-rgba { color: rgba(12, 12, 12, 1); }

/* Color Theme Swatches in HSLA */
.Man-with-mask-holding-lightsaber-1-hsla { color: hsla(354, 96, 48, 1); }
.Man-with-mask-holding-lightsaber-2-hsla { color: hsla(348, 90, 44, 1); }
.Man-with-mask-holding-lightsaber-3-hsla { color: hsla(0, 96, 22, 1); }
.Man-with-mask-holding-lightsaber-4-hsla { color: hsla(0, 96, 7, 1); }
.Man-with-mask-holding-lightsaber-5-hsla { color: hsla(0, 0, 5, 1); }

:root {
  --primary-white: #ffffff;
  --primary-red: #ef4035;
  --primary-dark: #212529;
  --primary-hover-red: #f33501;
  --primary-light-grey: #f4f4f4;
  --primary-slate-grey: #bbbbbb;
  --primary-dark-grey: #444444;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0,0,0,0.8);
}

body {
  font-family: "Roboto Condensed" , sans-serif;
}
