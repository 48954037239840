/* Services */
.services {
  text-align: center;
  padding-bottom: 2rem;
  background-color: var(--primary-dark);
  color: var(--primary-white);
}

.services h1 {
  text-transform: uppercase;
  text-decoration: overline;
  color: var(--primary-red);
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background-color: var(--primary-red);
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: .625rem solid var(--primary-red);
  border-radius: 10px;
  padding: .625rem;
  margin-bottom: 1.875rem;
  background-color: var(--primary-slate-grey);
  color:var(--primary-dark);
  box-shadow: 10px 10px 5px var(--primary-black);
  transition: 0.3s ease-in-out;
}

.services .box:hover, .box:focus {
  height: 16rem;
  border-bottom: .925rem solid var(--primary-red);
  border-radius: 10px;
  padding: .625rem;
  margin-bottom: 1.875rem;
  background-color: var(--primary-dark-grey);
  color:var(--primary-white);
  box-shadow: 25px 25px 20px var(--primary-black);
  transform: translateY(-0.25em);
  // transition: height .25s;

}

.services .box:hover .icon {
  color: var(--primary-black);
  transition: 0.3s ease-in-out;
}

.services .box:hover .circle {
  background-color: var(--primary-white);
  transition: 0.3s ease-in-out;
}
