.experience {
  h1 {
    text-transform: uppercase;
    color: #000000;
  }
    .container {
        .row {
            padding: 0;
            margin: 0;
            .col-md-6 {
                padding: 0;
                margin: 0;
                  .marker-right {
                    margin-left: auto;
                    transform: translateX(10px);
                  }
                  .marker-right, .marker-left {
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    border: 0.3125rem solid var(--primary-dark);
                    background: var(--primary-red);
                    margin-top: 0.625rem;
                    z-index: 3;
                  }
                  .marker-left {
                    margin-right: auto;
                    transform: translateX(-10px);
                  }
                  .timeline-content {
                    width: 95%;
                    padding: 0 0.9375rem;
                    color: var(--primary-black);
                      h3 {
                        margin-top: 0.3125rem;
                        color: var(--primary-hover-red);
                        font-weight: 900;
                      }
                  }
            }  
        }
        .row:nth-child(odd) {
            .col-md-6 {
            box-shadow: 4px 0 0 0 grey;
            }
        }
        .row:nth-child(even) {
            .col-md-6 {
            border-left: 4px solid grey;
            }
        }
    @media(max-width:768px) {
        .row:nth-child(odd) {
                .col-md-6 {
                    box-shadow: 0 0 0 0 grey;
                    border-left: 4px solid grey;

                    .marker-right {
                        margin-right: auto;
                        transform: translateX(-10px);
                        margin-left: 0;
                    }
                }
            }
        }
    }
}