.h1 {
   text-align: center;
   color: green;
}
  
.scroll-btn {
   position: fixed; 
   display: flex;
   font-size: 1.5rem;
   z-index: 1;
   color: var(--primary-hover-red);
   right: 5%;
   bottom: 5%;
   background-color: var(--primary-black);
   border: none;
   &:hover, &:focus{
    outline: none;
    background-color: var( --primary-black);
   }

}